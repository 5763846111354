import * as React from 'react';
import { IColumn } from 'office-ui-fabric-react';
import createLazyContainer from '../lazyContainer';
import { Dictionary, EntityType, IBaseEntity, IExtensibleEntity, IWithName, SortDirection } from "../entities/common";
import * as Metadata from "../entities/Metadata";
import { DisplayField } from "../components/common/DisplayField";
import { IOrderBy } from "../store/views";
import { toDictionaryByName } from '../components/utils/common';
import { namesof } from '../store/services/metadataService';
import { IDependencyAttrs, isLinkedSubentity, ISteeringCommitteeAttrs } from '../entities/Subentities';

const columns = import.meta.glob('../components/views/list/columns/**/*.tsx');
const componentCache = new Dictionary<any>();
const getValueFuncCache = new Dictionary<(item: any, field: Metadata.Field) => any>();

export class ViewService {
    static createListColumn<T>(componentName: string) {
        let component = componentCache[componentName];
        if (!component) {
            const loader = columns[`../components/views/list/columns/${componentName}.tsx`]()
                .then((module: any) => {
                    if (module.getValue || module.default.getValue || module.isActionable || module.default.isActionable) {
                        getValueFuncCache[componentName] = module.getValue ?? module.default.getValue;
                    }
                    return { default: module.default };
                })
            component = componentCache[componentName] = createLazyContainer<T>(() => loader);
        }

        return component;
    }

    public static getHandler(field: Metadata.Field) {
        const componentPath: string | undefined = field.settings?.views?.list?.componentPath;
        if (!componentPath) {
            return undefined;
        }
        
        return getValueFuncCache[componentPath];
    }

    public static isActionable(field: Metadata.Field): boolean {
        return !!field.settings?.views?.list?.isActionable;
    }

    static getFakeFieldColumnValue(entity: IExtensibleEntity, field: Metadata.Field): any {
        const handler = ViewService.getHandler(field);
        if (!handler) {
            console.warn('Value extractor map does not contain definition for field "' + field.name + '"');
            return undefined;
        }

        return handler(entity, field);
    }

    static getFieldName(column: string | Metadata.ISubViewColumn) {
        return typeof column === "string" ? column : column.id;
    }

    static buildColumn(
        fieldName: string,
        mapByName: Dictionary<Metadata.Field>,
        orderBy: IOrderBy | IOrderBy[] | undefined,
        isTimelineView: boolean,
        entityType: EntityType,
        columns?: Metadata.ISubViewColumn[],
        disableNavigation?: boolean,
        isArchived?: boolean
    ): IColumn | null {
        const field = mapByName[fieldName];
        if (!field) {
            return null;
        }

        const isMultiline = ViewService.isFieldMultiline(field);
        const defaultSettings = {
            isMultiline,
            minWidth: (isMultiline || field.type === Metadata.FieldType.Resource || field.type === Metadata.FieldType.User) ? 200 : 100,
            maxWidth: 300,
            isResizable: true
        }
        const fieldSettings = Object.assign({}, defaultSettings, field.settings?.views?.list || {});
        const origin = columns && columns.find(_ => _.id === field.id);
        const columnSettings = origin
            ? {
                ...fieldSettings,
                maxWidth: origin.width || fieldSettings.maxWidth,
                minWidth: origin.width || fieldSettings.minWidth
            }
            : fieldSettings;

        const fieldSort = orderBy && (Array.isArray(orderBy)
            ? orderBy.find(_ => _.fieldName === field.name)
            : orderBy.fieldName === field.name
                ? orderBy
                : undefined);

        disableNavigation ||= isArchived && field.type === Metadata.FieldType.Resource;

        return {
            ...columnSettings,
            key: field.id,
            name: columnSettings.label !== undefined ? columnSettings.label : Metadata.getLabel(field),
            fieldName: field.name,
            onRender: (item?: IExtensibleEntity, index?: number, column?: IColumn) => {
                if (columnSettings.componentPath) {
                    const component = ViewService.createListColumn<IListViewFieldColumn<IExtensibleEntity>>(columnSettings.componentPath);
                    return React.createElement(component, { entity: item, field, className: "font-14", isTimelineView, entityType, disableNavigation });
                }
                return React.createElement(
                    'span',
                    { className: "list-grid-cell" },
                    item ? React.createElement(DisplayField, { field, entity: item, entityType, disableNavigation }) : undefined);
            },
            isSorted: !!fieldSort,
            isSortedDescending: !!fieldSort && fieldSort.direction === SortDirection.DESC,
            headerClassName: columnSettings?.iconName ? "with-icon" : undefined
        };
    }

    static buildCellRenderer<T>(item: T, entityType: EntityType, fields: Metadata.Field[], field: Metadata.Field, isFieldFake: (field: Metadata.Field) => boolean) {
        const fieldName = Metadata.findMatchingFieldName(fields, field, isFieldFake);
        return this.renderCell(fieldName
            ? ViewService.buildColumn(fieldName, toDictionaryByName(fields), undefined, true, entityType)
            : null, item);
    }
    
    static renderCell(column: IColumn | null, item?: any) {
        return column ? column.onRender!(item, undefined, column) : React.createElement(React.Fragment);
    }

    static isMenuColumn(entityType: EntityType, column: string | Metadata.ISubViewColumn): boolean {
        const mandatories = ViewService.getViewMandatoryFields(entityType);
        return mandatories?.length
            ? mandatories[0] === ViewService.getFieldName(column)
            : false;
    }

    static getViewMandatoryFields = (entityType: EntityType): string[] => {
        switch (entityType) {
            case EntityType.Portfolio:
            case EntityType.Program:
            case EntityType.Project:
            case EntityType.KeyDate:
            case EntityType.ActionItem:
            case EntityType.LessonLearned:
            case EntityType.ChangeRequest:
            case EntityType.Issue:
            case EntityType.Iteration:
            case EntityType.KeyDecision:
            case EntityType.PurchaseOrder:
            case EntityType.Invoice:
            case EntityType.Risk:
            case EntityType.StrategicPriority:
            case EntityType.Resource:
            case EntityType.Challenge:
            case EntityType.Idea:
            case EntityType.Roadmap:
            case EntityType.RoadmapItem:
            case EntityType.Objective:
            case EntityType.KeyResult:
            case EntityType.Task:
            case EntityType.Deliverable:
            case EntityType.ArchivedProject:
            case EntityType.TimeTrackingEntry:
            case EntityType.MyWork:                
                return namesof<IWithName>(["Name"]);
            case EntityType.Dependency:
                return namesof<IDependencyAttrs>(["Type"]);
            case EntityType.SteeringCommittee:
                return namesof<ISteeringCommitteeAttrs>(["Person"]);
            default:
                return [];
        }
    }

    static isMandatory = (field: Metadata.Field, mandatoryFields?: string[]): boolean =>
        !!mandatoryFields?.includes(field.name);

    static isReadonlyField = (
        field: Metadata.Field,
        readOnlyFields: string[] | ((entity: any) => string[]) | undefined,
        entityType: string,
        entity: any | undefined,
        editableNativeFieldsForLinkedEntities?: ((entity: any) => string[]) | string[],
        theOnlyEditableFieldsForLinkedEntities?: string[],
    ): boolean => {
        return !!readOnlyFields && includesField(readOnlyFields)
            || (isLinkedSubentity(entityType, entity)
                && !!theOnlyEditableFieldsForLinkedEntities && !theOnlyEditableFieldsForLinkedEntities.includes(field.name))
            || (field.isNative && !!entityType && isLinkedSubentity(entityType, entity)
                && (!editableNativeFieldsForLinkedEntities || !includesField(editableNativeFieldsForLinkedEntities))
            );

        function includesField(fieldNames: string[] | ((entity: any) => string[])): boolean {
            return Array.isArray(fieldNames) && fieldNames.includes(field.name)
                || !Array.isArray(fieldNames) && fieldNames(entity).includes(field.name);
        }
    }

    static isFieldMultiline = (field: Metadata.Field): boolean => !!field.settings?.multiline;

    static buildBulkUpdates = (item: IBaseEntity, field: Metadata.Field, value: any, extraUpdates?: Dictionary<any>): Dictionary<any> => {
        return { [item.id]: ViewService.buildUpdates(field, value, extraUpdates) };
    }

    static buildUpdates = (field: Metadata.Field, value: any, extraUpdates?: Dictionary<any>): Dictionary<any> => {
        return { ...extraUpdates, [field.name]: value };
    }
}

export interface IListViewColumn<TEntity> {
    entity: TEntity;
    className?: string;
    isTimelineView?: boolean;
    entityType?: EntityType;
    disableNavigation?: boolean;
}

export interface IListViewFieldColumn<TEntity> extends IListViewColumn<TEntity> {
    field: Metadata.Field;
}